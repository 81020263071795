import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSignaturePad from 'vue-signature-pad'

import i18n from '@/libs/i18n'
import flatpickr from 'flatpickr'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Signature
Vue.use(VueSignaturePad)

Vue.directive('visible', (el, binding) => {
  // eslint-disable-next-line no-param-reassign
  el.style.visibility = binding.value ? 'visible' : 'hidden'
})

Vue.use(flatpickr)
// eslint-disable-next-line import/extensions
const Dutch = require('flatpickr/dist/l10n/nl.js').default.nl

flatpickr.localize(Dutch)

// Feather font icon - For form-wizard
require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
