export default [
  {
    path: '/offer/:id?',
    name: 'app-offer',
    component: () => import('@/views/pages/offer/offer.vue'),
    meta: {
      pageTitle: 'Uw voorstel',
    },
  },
  {
    path: '/mandate/:id?',
    name: 'app-offer',
    component: () => import('@/views/pages/mandate/mandate.vue'),
    meta: {
      pageTitle: 'SEPA Mandaat',
    },
  },
  {
    path: '/arrangement/:token?',
    name: 'app-arrangement',
    component: () => import('@/views/pages/arrangement/arrangement.vue'),
    meta: {
      pageTitle: 'Betalingsregeling',
    },
  },
]
